[type=checkbox]{
    width: 20px;
    height: 20px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    transition: background-color 0.3s, border-color 0.3s;
    position: relative;
  }
  
  [type=checkbox]:checked {
    background-color: #2196F3;
    border-color: #2196F3;
  }
  
  [type=checkbox]:checked::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 6px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }