@media only screen and (max-width: 767px) {
    .l-fotter {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
}

.l-fotter {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 40px;
}