.c-card {
  min-height: 65vh;
    &__title {
      font-size: 62px;
      font-weight: 300;
    }
  
    &--preferencias {
      &__body {}
    }

    &--1 {
      min-height: 76vh;
    }

  }

  @media screen and (max-width: 767px) {
  .c-card{
    min-height: 0vh !important;
  }
}
