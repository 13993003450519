@media only screen and (max-width: 767px) {
  .g--width-100-on{
    width: 100%;
    padding: 12px;

  }
}
.g--width {
    &-100 {
      width: 100%
    }

    &-70-px {
      width: 70px
    }

    &-100-px {
      width: 100px
    }

    &-130-px {
      width: 130px;
    }

    &-150-px {
      width: 150px;
    }

    &-152-px {
      width: 152px;
    }

    &-200-px {
      width: 200px;
    }

    &-300-px {
      width: 300px;
    }

    &-420-px {
      width: 420px;
    }

    &-600-px {
      width: 600px;
    }

    &-900-px {
      width: 900px;
    }

    &-20 {
      width: 20%;
    }

    &-25 {
      width: 25%;
    }

    &-30 {
      width: 30%;
    }

    &-80 {
      width: 80%;
    }
  }
