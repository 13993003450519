
  .content {
    display: block;
  }
  
  .responsive-toolbar {
    display: none;
    height: 64px; 
  }
  
  //Tamaño en el que aparece el menu
  @media (max-width: 422px) {
    .content {
      display: none;
    }
  
    .responsive-toolbar {
      display: flex;
    }
  }
  //Tamaño en el que desaparece el menu
  @media (min-width: 423px) {
    .content-menu {
      display: none;
    }
  }
  
  .contenido-imagen,
  .contenido-menu {
    display: flex;
    align-items: center;
  }
  
  .contenido-imagen {
    width: 50%;
  }
  
  .contenido-menu {
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }
  
  .contenido-imagen app-imagen-destinux {
    max-width: 100%;
    height: auto;
    display: block; 
    max-height: 100%;
  }
  
  //Menu par acuando hacemos clic en el icono de usuario
.mat-menu-panel {
    border-radius: 10px !important;
    background-color: $color-fondo;
  }
  

  