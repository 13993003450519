
@import
'./01_utilities/_variables.scss';

@import
'./02_base/_override.scss',
'./02_base/_reset.scss';
@import
'./03_layout/l-fotter.scss',
'./03_layout/l-page',
'./03_layout/l-modal',
'./03_layout/l-flex.scss';
@import
'./04_components/_c-button.scss',
'./04_components/_c-card.scss',
'./04_components/_c-img.scss',
'./04_components/_c-panel.scss',
'./04_components/_c-body.scss',
'./04_components/_c-column.scss',
'./04_components/_c-table.scss',
'./04_components/_c-text.scss',
'./04_components/_c-section.scss',
'./04_components/_c-input.scss',
'./04_components/_c-radiobutton',
'./04_components/_c-checkbox',
'./04_components/_c-alertas',
'./04_components/_c-menu',
'./04_components/c-material',
'./04_components/c-modal';


@import
'./06_global/_width.scss',
'./06_global/_redondeado.scss',
'./06_global/_bkcolor.scss',
'./06_global/_colors.scss',
'./06_global/_height.scss',
'./06_global/_padding.scss',
'./06_global/_margin.scss';

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');

body{
  background-color: $color-fondo;
  overflow-x: hidden;
}


.titulo
{
  font-size: 1.2em;
  font-weight: lighter;
}


//Ajustar titulo de Documentos y Tarjetas, y los botones  para pantallas pequeñas
@media screen and (max-width: 455px) {
  .justify-content-between {
    justify-content: start !important; 
  }
}

//Para ir cambiando como se ven los botones de Eliminar y Adjuntar en Documentos
@media (min-width: 500px) and (max-width: 767px){
  .col-sm-6 {
      flex: 0 0 auto !important;
      width: 50% !important;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .col-sm-6 {
      flex: 0 0 auto;
  }
}


@media (max-width: 445px) {
  .col-custom {
      flex: 0 0 100% !important;
      max-width: 100% !important;
  }
}

@media (max-width: 767px) {
  .row{
  margin: 0px !important}
  }

.mat-table{
  background: none !important;
}