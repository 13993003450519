@media only screen and (max-width: 767px) {
    .c-img--header{
        width: 526px;
        height: 5em;
        margin-top: 96px;
        margin-bottom: 96px;    }
  }
.c-img {
    &--header {
        width: 526px;
        margin-top: 96px;
        margin-bottom: 96px;
    }
}