
.mat-tab-body-content {
    overflow: hidden !important;
    padding-bottom: 2px;
  }

  .mat-select-panel {
    max-width: min-content !important;
  }
  @media screen and (max-width: 1000px) {
    .mat-select-panel  {
      width: 50px !important;
    }
  }

  //subir label cuadno haces clic sobre el input
.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1em 0 1em 0;
    margin-top: -4px;
  }

  //color de las cards
.mat-horizontal-content-container{
    background-color: $color-fondo;
    padding: 0 !important;
  }
  

  //margen para que se vea el label en docuemtnos y tarjetas
.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: .35em 0 !important;
  }
  
  
  //color del header  
  .mat-horizontal-stepper-header-container{
    background-color: $color-fondo;
  }
  
  //Las lienas para separar entre diferente documentos y tarjetas
  mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    border-bottom-color: rgba(0, 0, 0, .12);
    padding-top: 20px;
    padding-bottom: 20px;
  } 
  
  //El label que sube arriba al estar sobre un input
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0.1em 0.5em !important;
    
  }
  
  .mat-form-field-appearance-outline .mat-form-field-label.mat-form-field-empty {
    font-size: 18px !important; /* Tamaño del label cuando está dentro del input */
  }
  
  //Elimina la linea inferior del boton de la papelera
  .sin-borde .mat-cell {
    border-bottom: none; 
  }
  .mat-row, .mat-header-row, .mat-footer-row {
    display: flex !important;
    flex-wrap: wrap !important; 
    text-align: center;
  }
  .mat-cell, .mat-header-cell, .mat-footer-cell {
    flex: 0 1 18% !important; 
    max-width: 20% !important; 
    box-sizing: border-box !important;
    padding-left: 24px !important; 
  }
  
  //Quitar padding en la izquierda cuando las pantallas son pequeñas
  @media screen and (max-width: 767px) {
  .mat-cell, .mat-header-cell, .mat-footer-cell {
    padding-left: 0px !important; 
  }
  }
  
  @media screen and (max-width: 767px) {
    .mat-cell, .mat-header-cell, .mat-footer-cell {
        flex-basis: 100% !important; 
        max-width: 100% !important; 
    }
    .mat-cell.cell-big, .mat-header-cell.cell-big, .mat-footer-cell.cell-big {
      flex: 1 1 100% !important;
      max-width: 100% !important;
    }
    .mat-cell.cell-small, .mat-header-cell.cell-small, .mat-footer-cell.cell-small {
      flex: 0 1 100% !important;
      max-width: 100% !important;
  }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1130px) {
    .mat-cell, .mat-header-cell, .mat-footer-cell {
        flex-basis: 50% !important;
        max-width: 50% !important;
    }
    .mat-cell.cell-big, .mat-header-cell.cell-big, .mat-footer-cell.cell-big {
      flex: 1 1 50% !important;
      max-width: 50% !important;
    }
    .mat-cell.cell-small, .mat-header-cell.cell-small, .mat-footer-cell.cell-small {
      flex: 0 1 50% !important;
      max-width: 50% !important;
  }
  }
  
  @media screen and (min-width: 1131px) and (max-width: 1600px)  {
    .mat-cell, .mat-header-cell, .mat-footer-cell {
        flex-basis: 30% !important;
        max-width: 40% !important;
    }
    .mat-cell.cell-big, .mat-header-cell.cell-big, .mat-footer-cell.cell-big {
      flex: 1 1 30% !important;
      max-width: 40% !important;
    }
    .mat-cell.cell-small, .mat-header-cell.cell-small, .mat-footer-cell.cell-small {
      flex: 0 1 30% !important;
      max-width: 40% !important;
  }
  }
  
  .outline{
    font-size: 40px !important;
  }
  
  //Ajustar celda segun cuanto queremos que ocupen en la tabla
  .cell-small {
    flex: 0 1 10% !important; 
    max-width: 50% !important;
  }
  
  .cell-medium {
    flex: 0 1 15% !important; 
    max-width: 50% !important;
  }
  
  .cell-big {
    flex: 0 1 20% !important; 
    max-width: 20% !important;
  }
  
  //para que los iconos no se nos vayan arriba
  .mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
     top: 0px !important; 
  }
  
  //para el icono de calendario
  .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-datepicker-toggle-default-icon, .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
     height: 40px !important;
     display: inline !important;
     padding-bottom: 5px !important;
  }
  
  .mat-input-element mat-form-field-autofill-control mat-datepicker-input c-input__calendar ng-tns-c102-49 ng-valid cdk-text-field-autofill-monitored ng-touched ng-dirty{
    padding-bottom: 6px;
  }

  .input.mat-input-element {
    margin-top: 0 !important; 
 }

  .mat-form-field {
    width: 100% !important;
  }

  .table-responsive {
    overflow-x: auto !important;
  }
  
  
  /* Estilos responsivos */
  @media (max-width: 768px) {
    .table-responsive {
      display: block !important;
      width: 100% !important;
      overflow-x: auto !important;
    }
    .mat-table {
      width: 100%!important;
    }
  }

  mat-cell:last-of-type, mat-header-cell:last-of-type, mat-footer-cell:last-of-type {
    padding-right: 0 !important;
 }
 
 //Ordenamos las celdas dependiendo de la posición que queremos
 mat-row, mat-header-row, mat-footer-row {
   display: flex;
 }
 
 //Sirve para que los documentos para pantallas inferiores a 1400px el boton de de acciones aparezca al final
 @media (max-width: 1600px) {
   .TipoDocumento-cell {
     order: 0;
   }
   .PaisExpedicion-cell {
     order: 0;
   }
   .NumDocumento-cell {
     order: 0;
   }
   
   .FechaExpedicion-cell {
     order: 0;
   }
   .FechaCaducidad-cell {
     order: 0;
   }
   .PaisNacionalidad-cell {
     order: 0;
   }
   .InfoFamiliaNumerosaES.CodigoComunidad-cell {
     order: 0;
   }
   
   .InfoFamiliaNumerosaES.Tipo-cell {
     order: 0;
   }
   .Acciones-cell {
     order: 1; 
   }
 }
 
 //Cuando no se muestran en una unica columna que el boton de acciones y algunos inputs bajen a la siguiente fila
 @media (max-width: 1599px) {
   .TipoDocumento-cell {
     order: 0;
   }
   .PaisExpedicion-cell {
     order: 0;
   }
   .NumDocumento-cell {
     order: 0;
   }
   
   .FechaExpedicion-cell {
     order: 1;
   }
   .FechaCaducidad-cell {
     order: 1;
   }
   .PaisNacionalidad-cell {
     order: 0;
   }
   .InfoFamiliaNumerosaES.CodigoComunidad-cell {
     order: 0;
   }
   
   .InfoFamiliaNumerosaES.Tipo-cell {
     order: 0;
   }
   .Acciones-cell {
     order: 1; 
   }
 }
 
 //Sirve para que en las tarjetas de fidelizacion el boton de acciones aparezca al final de la primera fila
 @media (max-width: 1600px) {
   .TipoProducto-cell {
     order: 0;
   }
   .Compania-cell {
     order: 0;
   }
   .OtroCodigo-cell {
     order: 1;
   }
   
   .NombreAlianza-cell {
     order: 1;
   }
   .CodigoAlianza-cell {
     order: 1;
   }
   .CodigoAlianzaAereo-cell {
     order: 1;
   }
   .CodigoAereo-cell {
     order: 1;
   }
   
   .Numeracion-cell {
     order: 0;
   }
   .FechaCaducidad-cell {
     order: 0;
   }
   .Notas-cell {
     order: 0;
   }
   .Acciones-cell {
     order: 1; 
   }
 }
 
 //Cuando no se muestran en una unica columna que el boton de acciones y algunos inputs bajen a la siguiente fila
 @media (max-width: 1599px) {
   .TipoProducto-cell {
     order: 0;
   }
   .Compania-cell {
     order: 0;
   }
   .OtroCodigo-cell {
     order: 0;
   }
   
   .NombreAlianza-cell {
     order: 0;
   }
   .CodigoAlianza-cell {
     order: 0;
   }
   .CodigoAlianzaAereo-cell {
     order: 0;
   }
   .CodigoAereo-cell {
     order: 0;
   }
   
   .Numeracion-cell {
     order: 1;
   }
   .FechaCaducidad-cell {
     order: 1;
   }
   .Notas-cell {
     order: 1;
   }
   .Acciones-cell {
     order: 1; 
   }
 }
  
 //Mensaje de error en validaciones a la izquierda
.mat-error {
    text-align: left;
  }

  
.mat-form-field-wrapper{
    padding-top: 10px !important;
    padding-bottom: 0 !important;
  }
  