.mat-form-field .mat-form-field-underline {
  display: none;
}

.mat-form-field  .mat-form-field-flex {
  border-bottom: none;
}

.custom-outline .mat-form-field-outline {
  color: $color-borde__label;
}

.custom-outline .mat-form-field-underline {
  display: none; 
}

.custom-outline .mat-form-field-label {
  color: $color-label;
}

.custom-outline { 
  width: 100%;
}

//ocultar input que no se ve porque sus condicione sno han sido seleccionadas
.oculto {
  display: none !important;
} 

//Tamaños para cuanto queremos que ocupe cada input
.width-small {
  width: 50% !important;
}

.width-medium {
  width: 75%!important;
}

.width-large {
  width: 100%!important;
}

//Cambiar el * del label 
.mat-form-field-required-marker {
  color: red;
  font-size: 1.2rem;
  line-height: 0; 
  vertical-align: top; 
  position: relative; 
  top: 9px; 
}

//Quitar sombra al ahcer clic sobre algun input
.form-control:focus
{
  box-shadow: none;
}