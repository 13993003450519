.l-flex {
    display: flex;
  
    &--direction-column {
      flex-direction: column;
    }
  
    &--center {
      justify-content: center;
    }
  
    &--end {
      justify-content: end;
  
    }
  
    &--initial {
      justify-content: initial;
    }
  
    &--space-around {
      justify-content: space-around;
  
    }
  
  
    &--space-evenly {
      justify-content: space-evenly;
    }
  
    &--space-between {
      justify-content: space-between;
    }
  
    &__aling-items {
      &--center {
        align-items: center;
  
      }
  
      &--start {
        align-items: flex-start;
      }
  
      &--end {
        align-items: flex-end
      }
  
    }
    &__text-align {
      &--left {
        text-align: left;
  
      }
  
      &--center {
        text-align: center;
      }
      &--center {
        text-align: right;
      }
    }
  
    &--direction-row {
      flex-direction: row
    }
  
    &--wrap {
      flex-wrap: wrap
    }
  }