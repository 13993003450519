.g--height {
   &-48-px {
  height: 48px;
}
    &-100-px {
      height: 100px;
    }

    &-150-px {
      height: 150px;
    }

    &-250-px {
      height: 250px;
    }

    &-380-px {
      height: 380px;
    }

    &-450-px {
      height: 450px;
    }

    &-500-px {
      height: 500px;
    }

    &-660-px {
      height: 660px;
    }

    &-850-px {
      height: 850px;
    }

    &-100 {
      height: 100%
    }

    &-97 {
      height: 97%
    }

    &-90 {
      height: 90%
    }
    &-100-min100vh{
      height: 100%;
      min-height: 100vh;
    }
    @media screen and (max-width: 767px) {
      &-100-min100vh{
        min-height: 0px !important 
      }
    }


  }