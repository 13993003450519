@media only screen and (max-width: 767px) {
  .g--padding-b-4 {
    padding-bottom: 4px;
  }
}

.g--padding {
  &-r {
    &-25 {
      padding-right: 25px;
    }

    &-30 {
      padding-right: 30px;
    }
    &-40 {
      padding-right: 40px;
    }
  }

  &-t {
    &-10 {
      padding-top: 10px;
    }
    &-16 {
      padding-top: 16px;
    }
    &-45 {
      padding-top: 45px;
    }
  }

  &-l {
    &-4 {
      padding-left: 4px;
    }
    &-15 {
      padding-left: 15px;
    }
    &-40 {
      padding-left: 40px;
    }
  }

  &-b {
    &-10 {
      padding-bottom: 10px;
    }
    &-20 {
      padding-bottom: 20px;
    }
    &-40 {
      padding-bottom: 40px;
    }

    &-45 {
      padding-bottom: 45px;
    }
  }

  &-12 {
    padding:12px;
  }
  &-16{
    padding: 16px;
  }

  &-20 {
    padding: 20px;
  }

  &-30 {
    padding: 30px;
  }

  &-45 {
    padding: 45px;
  }

  &-110 {
    padding: 110px;
  }

  &-0-3-rem {
    padding: 0rem 3rem;
  }

  &-0-1-rem {
    padding: 0rem 1rem;
  }

  &__footer {
    padding: 20px 0;
  }
}


@media (max-width: 767px) {
  .g--padding-r-l-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}