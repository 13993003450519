@each $color in map-keys($palette) {
    $rango-color: map-get($palette, $color);

    @for $index-color from 0 to length($rango-color) {
        .g--color-#{$color}-#{$index-color+1} {
            color: nth($rango-color, $index-color+1);
        }
    }
}

.color-fondo{
    background-color: $color-fondo;
}

.color-titulos_apartados{
    color: $color-titulos;
}

.color-text-informacion{
    color: $color-text__informacion; 
}

.fondo-seccion-perfil{
    background-color: $color-fondo-barra__lateral;
}

.color-nombre-barra__lateral{
    background-color: $color-nombre-barra__lateral;
}

.color-boton{
    background-color: $color-boton;
}

.color-gris{
    background-color: $color-gris;
}