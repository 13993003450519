
.c-text {
    font-family: $fuente-letra;
    &--gd{
      font-size: 1.25rem;
    }
    &--md{
      font-size: 1rem;
    }
    &--pq{
      font-size: 0.875rem;
    }
    &--xp{
      font-size: 0.625rem;
    }
  
  }

  .c-titulo {
    font-family: $fuente-letra;
    &--xxg{
      font-size: 2.55rem;
    }
    &--xg{
      font-size: 2.375rem;
    }
    &--gd{
      font-size: 2rem;
    }
    &--md{
      font-size: 1.5rem;
    }
    &--pq{
      font-size: 1rem;
    }
    &--xp{
      font-size: 0.75rem;
    }
  
  }

  .c-subtitulo {
    font-family: $fuente-letra;
    &--gd{
      font-size: 0.75rem;
    }
    &--pq{
      font-size: 0.625rem;
    }
    &--xp{
      font-size: 0.5rem;
    }
  
  .-c-boton{
    font-family: $fuente-letra;
    &--primario__gd{
      font-size: 1rem;
    }
    &--primario__md{
      font-size: 0.75rem;
    }
    &--primario__pq{
      font-size: 0.625rem;
    }
    &--primario__xp{
      font-size: 0.5rem;
    }
  }


  }