.l-page {
    width: 100%;
    height: 100%;
  
    &__foter {
      position: absolute;
      bottom: 0px;
    }
    &--dody{
      margin: 0px;
    }
  }