.c-button {
    border: 1px solid;
    border-radius: 0.375rem;
  
    &--primary {
      cursor: pointer;
      background-color: $color-boton;
      border: 1px solid #ffffff;
      color: #fff; 
      &:hover {
        background-color: #1c4f97;
        color: white;
      }
    }
  
    &--secundary {
      cursor: pointer;
      background-color: white;
      color: #0082E6;
      &:hover {
        background-color: #3797ff;
        color: white;
      }
    }
  
   
  
    &--transparent {
      cursor: pointer;

      background-color: transparent;
      border-color: white;
      color: white;
      &:hover {
        background-color: #1c4f97;
        color: white;
      }
     
    }
  
    &--dangerous {
      cursor: pointer;

      background-color: $color-rojo;
      border-color: $color-rojo;
      color: white;
    }
    
  }
  
  .c-button--disabled {
    cursor: not-allowed !important;
    background-color: red;
   
  }
  
  .c-button-papelera{
      background: rgb(240, 190, 54);
      border-radius: 20px;
      border: none; 
      padding-top: 5px;
  }
  
  .c-button-documentos{
    background:$color-boton;
    border-radius: 20px;
    border: none; 
    padding-top: 5px;
    margin-right: 5px;
    color: $color-blanco;
  }
  //Boton de la x dentro del modal para borrar archivo ya subido
  .cancelar-button{
    width: 2rem;
    height: 2rem;
    background-color: $color-rojo;
    color: $color-blanco;
    border-color:  $color-blanco;
    border-radius: 6px;
    border: 1px solid white;
    }
 
//boton de la x dentro del modal para borrar archivo seleccionado pero aun no subido
  .p-button.p-button-icon-only {
    width: 2rem;
    height: 2rem;
    background-color: $color-rojo;
    border-color:  $color-blanco;
}
//desactivar el cambio de color al pasar por encima de la x del modal
.p-button.p-button-icon-only:hover {
  background-color: $color-rojo !important;
}

//tamaño de los botones del modal
.p-fileupload .p-fileupload-buttonbar .p-button {
  width: 140px;
  height: 50px;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .button-sm {
   width: 200px !important;
   border-radius: 2px !important;
   margin-right: 10px !important;
   margin-top: 10px !important;
   margin-bottom: 10px !important;
  }
}
