.c-table {
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px #00000029, 0 2px 10px #0000001f;
    border-radius: 6px;
    padding: 24px;
    height: auto;
    min-height: 385px;
  }

  .c-table-alert {
    background-color: #FFFFFF;
    box-shadow: 0 2px 5px #00000029, 0 2px 10px #0000001f;
    border-radius: 6px;
    padding: 24px;
    height: auto;
  }

  @media (max-width: 767px) {
  .c-table {
    min-height: 0px !important;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    margin-top: 0px !important;
  }
}