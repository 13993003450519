//Alerta para SUCCESS tick de dentro
.swal2-icon.swal2-success .swal2-success-line-tip,
.swal2-icon.swal2-success .swal2-success-line-long {
    background-color: $color-alerta-success !important; /* Cambia este color al que desees */
}
//Alerta para SUCCESS el borde
div:where(.swal2-icon).swal2-success .swal2-success-ring {
    border: .35em solid $color-alerta-success !important;
}

//Alerta para ERROR el borde
div:where(.swal2-icon).swal2-error {
    border-color: $color-alerta-error !important;
    color: $color-alerta-error !important;
    background-color: $color-blanco;
    border-width: 8px;
}
//Alerta para ERROR pero la x de dentro
div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
    background-color: $color-alerta-error !important;
    height: .6125em !important;
}
//Alerta para INFO
div:where(.swal2-icon).swal2-info {
    border-color: $color-alerta-info !important;
    color: $color-alerta-info !important;
    background-color: $color-blanco;
    border-width: 8px;
}
//Alerta para WARNING
div:where(.swal2-icon).swal2-warning {
    border-color: $color-alerta-warning !important;
    color: $color-alerta-warning !important;
    background-color: $color-blanco;
    border-width: 8px;
}

//Alerta para QUESTION
div:where(.swal2-icon).swal2-question {
    border-color: $color-alerta-question !important;
    color: $color-alerta-question !important;
    background-color: $color-blanco;
    border-width: 8px;
}


//alertas generales
div:where(.swal2-icon) .swal2-icon-content {
    font-size: 5.5em !important;
    padding-bottom: 5px;
}

// alertas toast
.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
    font-size: 2.0em !important;
    border-width: 5px;
}

.swal2-popup.swal2-toast .swal2-icon {
    border-width: 4px !important;
}

//Alerta de modal para cuando carga la pagina
.swal-modal-cargando {
    width: 800px !important; 
    padding: 2rem !important; 
    font-size: 1.2rem; 
    text-align: center; 
    top: 50% !important; 
    left: 50% !important; 
    transform: translate(-50%, -50%) !important; 
    position: fixed !important; 
  }
  
  //Tamaño para el icono de cargando
  .swal-icon-cargando {
    font-size: 3rem !important; 
  }
  
  
  
  
 