.c-section {
    height: 100%;
}

@media only screen and (max-width: 767px) {
    .c-section {
        height: auto;
    }
}

.seccion-documentos-tarjeta
{
  color: #00528A;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 0.5rem;
  font-weight: 700;
}

.seccion-datos-basicos
{
  color: #00528A;
  padding: 0.5rem;
  font-weight: 700;
}