@import "@fontsource/rubik"; // defaults to weight 400, Rubik has no 100 and 200 weight
@import "@fontsource/rubik/300.css";
@import "@fontsource/rubik/400.css";
@import "@fontsource/rubik/600.css";
@import "@fontsource/rubik/800.css";
@import "@fontsource/rubik/300-italic.css";
@import "@fontsource/rubik/400-italic.css";
@import "@fontsource/rubik/600-italic.css";
@import "@fontsource/rubik/800-italic.css";
@import '@fontsource/kumbh-sans/200.css';
@import '@fontsource/kumbh-sans/300.css';
@import '@fontsource/kumbh-sans/400.css';
@import '@fontsource/kumbh-sans/500.css';
@import '@fontsource/kumbh-sans/600.css';
@import '@fontsource/kumbh-sans/700.css';

$palette: (
  "principal":(#011419, #013041, #014B6B, #006094, #0073BD, #0082E6, #108FFE, #3B98FC, #65A8FB, #8DB9FC, #6a88b1, #B4CFFD, #DCECFF),
  "alternative":(#320A01, #691A02, #A62E03, #DE4802, #FD6B1C, #FD9753, #FDC090, #FEE2C8, #FFFFFF),
  "black":(rgb(0, 0, 0, 0.605)),
  "red": (#190501, #4C0E06, #80180F, #AE1E19, #DE2121, #E65851, #F18D84, #F9BCB3, #FEEAE6),
  "grey": (#0D0D0D, #292929, #474747, #636363, #5E5E5E, #808080, #9C9C9C, #BABABA, #D6D6D6, #F2F2F2),
  "green": (#021901, #0A470A, #1C731F, #2F9838, #40BF4F, #67D078, #90E4A1, #B8F5C7, #E6FEED),
  "white":(#B9CAFD, #CCD4F5, #D9DDF2, #E0E2F1, #E7E8F3, #EEEEF7, #F4F4FB, #E0E0E0, #F7F6FD),
  "yellow":(#321D01, #694202, #A16902, #D99902, #FDBF17, #FDD74E, #FDE786, #FEF5BE, #FFFEF5),
  "blue-icon":(#010E19, #0B233C, #1E3857, #334C70, #465F8B, #506EAF, #6380CA, #7690E5, #8DA3FC),
  "purple":(#4e005e, #8d1372, #a52093, #ca2bc2, #ff00dd)
);

//Colores Normales
$color-blanco: #ffffff;
$color-rojo: #c53030;
$color-gris: #f8f9fa;

//Colores fondo
$color-fondo: #f0f8fa;

//Colores label
$color-label: #84B1D9;
$color-borde__label: #cad6e0;

//Colores botones
$color-boton: #84B1D9;

//Colores letras
$color-titulos: #00528A;
$color-text__informacion: #666;

//Colores barra lateral
$color-fondo-barra__lateral: #7087AE;
$color-nombre-barra__lateral:  #427099;

//Colores Alertas
$color-alerta-success: #34d31f;
$color-alerta-error: #e63030;
$color-alerta-warning: #facd04;
$color-alerta-info: #2596d8;
$color-alerta-question: #888888;

//Tipo de letra
$fuente-letra: "Kumbh Sans";