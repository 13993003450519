.g--margin {
  &-10{
    margin:10px;
  }
  &-20 {
    margin: 20px;
  }
  &-30 {
    margin: 30px;
  }

  &-50 {
    margin: 50px;
  }

  &-r {
    &-6 {
      margin-right: 6px;
    }
    &-10 {
      margin-right: 10px;
    }

    &-15 {
      margin-right: 15px;
    }

    &-30 {
      margin-right: 30px;
    }
    &-40 {
      margin-right: 40px;
    }
  }

  &-t {
    &-5 {
      margin-top: 5px;
    }
    &-10 {
      margin-top: 10px;
    }

    &-15 {
      margin-top: 15px;
    }

    &-24 {
      margin-top: 24px;
    }

    &-30 {
      margin-top: 30px;
    }

    &-40 {
      margin-top: 40px;
    }

    &-50 {
      margin-top: 50px;
    }

    &-100 {
      margin-top: 100px;
    }
  }

  &-b {
    &-0 {
      margin-bottom: 0;
    }
    &-15 {
      margin-bottom: 15px;
    }

    &-24 {
      margin-bottom: 24px;
    }

    &-30 {
      margin-bottom: 30px;
    }

    &-35 {
      margin-bottom: 35px;
    }

    &-50 {
      margin-bottom: 50px;
    }
  }

  &-l {
    &-4 {
      margin-left: 4px
    }

    &-15 {
      margin-left: 15px;
    }

    &-30 {
      margin-left: 30px;
    }
  }
}
.no-margin {
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-top: 16px;
}
